import { PilotwebLanguage } from "apina-frontend";
import { TranslateService } from "@ngx-translate/core";

const LANGUAGE_KEY = "pilotwebLanguage";
const LEGACY_COOKIE_NAME = "language";

export function useAndStoreLanguage(translateService: TranslateService, language: PilotwebLanguage): void {
    const code = localeCodeForLanguage[language];
    localStorage.setItem(LANGUAGE_KEY, code);
    translateService.use(code);
}

export function useStoredLanguage(translateService: TranslateService): void {
    translateService.use(resolveStoredLanguage() ?? "fi");
}

function resolveStoredLanguage(): string | null {
    const language = localStorage.getItem(LANGUAGE_KEY);
    if (language != null)
        return language;

    // TODO: new mechanism added on 2024-09-25, remove this after few weeks
    const fallback = getCookieValue(LEGACY_COOKIE_NAME);
    if (fallback != null) {
        localStorage.setItem(LANGUAGE_KEY, fallback); // migrate automatically to new store
        return fallback;
    }

    return null;
}

const localeCodeForLanguage: Record<PilotwebLanguage, string> = {
    FINNISH: "fi",
    SWEDISH: "sv",
};

function getCookieValue(key: string): string | undefined {
    const prefix = `${key}=`;

    return document.cookie
        .split(/;\s*/)
        .find(it => it.startsWith(prefix))
        ?.substring(prefix.length);
}
